.about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.info-block {
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}