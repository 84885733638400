@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.main-menu {
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  align-items: center;

  &__links {
    display: flex;
    justify-content: flex-end;
    gap: 32px;
    align-items: center;

    @include max-width(800) {
      gap: 15px;
    }

    @include max-width(700) {
      display: none;
    }
  }

  &__item {
    color: $white;

    &--checked {
      text-decoration: underline;
    }
  }

  @include max-width(800) {
    gap: 15px;
  }

  .burger-menu {
    display: none;
    color: $white;

    &__block {
      display: none;
      flex-direction: column;
      max-width: 200px;
      background-color: $primary;
      border-radius: 6px;
      position: absolute;
      top: 50px;
      right: 150px;
      padding: 20px;
      gap: 10px;

      @include max-width(700) {
        display: flex;
      }
    }

    @include max-width(700) {
      display: flex;
    }
  }
}