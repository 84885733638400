@import '../../styles/variables.scss';

.documents {
  ul li::marker {
    color: $accent;
    font-size: 1.5em;
  }

  ul li {
    list-style: inside;
    padding-bottom: 10px;
  }

  a {
    color: $secondary;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}