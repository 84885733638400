@import '../../styles/variables.scss';

.price-table {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th, td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid $accent-light;
  }

  tr:hover {
    background-color: $accent-hover;
    color: $white;
  }
}

.table-header {
  align-self: center;
}