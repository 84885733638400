@import '../../styles/variables.scss';

.header {
  height: 80px;
  padding: 10px 64px;
  display: flex;
  justify-content: space-between;
  background-color: $accent;
  color: $white;
  align-items: center;
  position: relative;
}
