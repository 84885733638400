// Simple colors
$white: #fff;
$black: #000;

// Project main colors
$primary: #092930;
$secondary: #888686;
$accent: #085666;
$accent-light: #10859c;

// Style guide colors
$error: #d03b0e;
$accent-hover: #15937a;
$disabled: #e9e9e9;

// Other variables
$drop-shadow: 0 3px 10px rgba(0 0 0 / 11%);
$box-shadow: 2px 6px 10px rgba(0 0 0 / 8%);
$light-box-shadow: 2px 2px 8px rgba(0 0 0 / 40%);
$blue-gradient: linear-gradient(90deg, #001167 0.69%, #00338c 35.14%, #549dd5 100%);
$default-border: 1px solid $secondary;
$default-transition: all 0.35s ease-out;

// Animations
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

$animation-spin: spin 6s linear 0s infinite;
