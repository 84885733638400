html,
body,
#app {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.42;
  -webkit-user-select: text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  letter-spacing: inherit;
}

section {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: inherit;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

figure,
ul,
p {
  margin: 0;
}

img {
  display: block;
}

svg {
  display: block;
}

header {
  padding: 0;
}

footer {
  font-size: inherit;
  font-style: normal;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.page-container {
  width: 100%;
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
  padding: 0 48px;

  @include maxWidth(1500) {
    padding: 0 22px;
  }

  @include maxWidth(768) {
    padding: 0 15px;
  }
}

@mixin maxWidth($resolution) {
  @media screen and (max-width: #{$resolution}px) {
    @content;
  }
}
